import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Project from "./Project";
import lightBlue from "@mui/material/colors/lightBlue";
import logo from './logo.svg';
import Link from "@mui/material/Link";

const defaultTheme = createTheme();

const project = [
    {
        title: 'Прогнозирование спроса',
        description: 'На 30 дней - текущая точность на весь горизонт прогнозирования',
        descriptionList: ['Прогнозирует спрос', 'Использует сотни методов расчётов', 'Запускается каждый день', 'На горизонте 30 суток прогнозирует\n' +
        'по каждому резервуару и по каждому продукту на каждый час'],
        imageLabel: 'Прогнозирование спроса',
        number: 1,
    },
    {
        title: 'Интегральное планирование',
        description: 'На 7 дней - система решает, сколько везти ресурса дней в каждый из резервуаров',
        descriptionList: [
            'Планирует поставки верхнеуровнево без детализации, но на 7 дней и со всех нефтебаз на все АЗС',
            'Определяется наилучшая комбинация использования бензовозов и водителей',
            'Происходит выбор наилучшего решения',
            'Оптимизация по расчёту десятков параметров'
        ],
        imageLabel: 'Интегральное планирование',
        number: 2,
    },
    {
        title: 'Детальное планирование',
        description: 'Каждую смену - система выбирает лучшее решение из квинтиллиона возможных комбинаций',
        descriptionList: [
            'Планирует работы каждого конкретного бензовоза с глубокой детализацией',
            'Определяет наилучший вариант работы по ряду заданных критериев'
        ],
        imageLabel: 'Детальное планирование',
        number: 3,
    },
    {
        title: 'Диспетчеризация. Локальное перепланирование',
        description: 'FULL TIME - Система постоянно отслеживает исполнение плана',
        descriptionList: [
            'Предлагает варианты решений по отклонениям в исполнении плана потенциально ведущим к негативным последствиям',
            'Отслеживает в режиме реального времени всё, что происходит в товаропроводящей сети',
            'Постоянно понимает, где находится каждый бензовоз, его статус, куда он едет, что происходит на каждой АЗС, и к чему это может привести'
        ],
        imageLabel: 'Диспетчеризация Локальное перепланирование',
        number: 4,
    },
    {
        title: 'Мониторинг и метрики эффективности',
        description: '100% - Показателей под контролем',
        descriptionListTitle: 'Позволяет осуществлять мониторинг ключевых показателей эффективности диспетчеризации НП и операционных метрик:',
        descriptionList: [
            'Процент автоматизированного планирования',
            'Процент исполнения планов',
            'Процент рейсов с отклонением более 30 минут',
            'Сходимость план/факт',
        ],
        imageLabel: 'Мониторинг и метрики эффективности',
        number: 5,
    },
    {
        title: 'Интерфейсы с источниками данных',
        description: '99,9% - Релевантных исходных данных на момент расчета и принятия решения',
        descriptionListTitle: 'Позволяет осуществлять загрузку и верификацию качества данных, 24/7 с частотой от одного раза\n' +
            'в минуту одновременно от всех:',
        descriptionList: [
            'датчиков уровня НП во всех резервуарах АЗС',
            'датчиков уровня НП во всех резервуарах нефтебаз',
            'датчиков уровня/полноты налива/слива всех бензовозов',
            'датчиков геопозиции всех бензовозов',
            'систем коммерческого/бухгалтерского учета',
            'систем учета автотранспорта и водителей',
            'систем промышленной безопасности',
            'систем контроля допуска машин и водителей до работы',
        ],
        number: 6,
    },
    {
        title: 'Интерфейсы Интерфейсы с участниками процесса',
        description: '100% - Информированных участников процесса поставки НП',
        descriptionListTitle: 'Позволяет осуществлять коммуникацию 24/7 одновременно со всеми:',
        descriptionList: ['представителями перевозчиков', 'АЗС', 'водителями Бензовозов'],
        number: 7,
    },
];

function App() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline/>
            <Container maxWidth="lg">
                <main>
                    <Paper
                        sx={{
                            position: 'relative',
                            background: lightBlue[800],
                            color: '#fff',
                            mb: 4,
                        }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        p: {xs: 3, md: 6},
                                        textAlign: 'center',
                                    }}
                                >
                                    <img src={logo} className="App-logo" alt="logo"/>
                                    <Typography component="h1" variant="h5" gutterBottom>
                                        Мономакс Интеллектуальные Технологии
                                    </Typography>
                                    <Typography variant="h5" paragraph>
                                        Разработка и внедрение системы комплексной оптимизации,
                                        вторичной логистики
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper sx={{
                        p: {xs: 3, md: 6},
                        mb: 4,
                    }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h6" paragraph>
                                    Наша команда разрабатывает на широком стеке технологий, в том числе:
                                </Typography>
                                <ul>
                                    <li>
                                        Языки программирования: java, python, kotlin, go, gams, js, typescript, c++, 1c
                                    </li>
                                    <li>
                                        Фреймворки: spring, fastapi, flask, pandas, airflow, react, xaarin, vue.js,
                                        node.js
                                    </li>
                                    <li>
                                        Базы данных: redis, postgreSQL, PostgresPRO, oracle pl/sql, mysql, sql server,
                                        TimescaleDB, mongodb, Patroni,
                                    </li>
                                    <li>
                                        Инструменты и подходы DevOps: azure, yandex cloud, kubernetes, openshift,
                                        gitlab/github,SonarQube, docker, ansible, terraform, prometheus, grafana, IaaS,
                                        PaaS, SaaS, Oracle, Cisco, Zabbix, kafka, kibana, elasticsearch, proxmox,
                                        RabbitMQ,
                                        kafka, celery, AMQ, Zookeeper, etcd, Confluent Platform, ELK, Xunit, Vanessa,
                                        Pytest, Pylint
                                    </li>
                                </ul>
                                <Typography variant="h6" paragraph>
                                    Наши заказчики - это крупные корпоративные клиенты, работающие в нефтегазовом
                                    секторе,
                                    топливно-логистические компании и прочие.
                                </Typography>
                                <Typography variant="h6" paragraph>
                                    Основной наш продукт автоматизирует процессы диспетчеризации вторичной логистики. Мы
                                    занимаемся его разработкой, внедрением и запуском для крупных коорпоративных
                                    клиетов.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Typography variant="h4" sx={{
                        textAlign: 'center',
                        background: lightBlue[800],
                        color: '#fff',
                        p: {xs: 2, md: 3},
                    }}> Наш продукт умеет:</Typography>
                    <Grid container spacing={4} sx={{
                        my: 3,
                    }}>
                        {project.map((post) => (
                            <Project key={post.title} post={post}/>
                        ))}
                    </Grid>
                    <Typography variant="h4" sx={{
                        textAlign: 'center',
                        my: 3,
                        background: lightBlue[800],
                        color: '#fff',
                        p: {xs: 2, md: 3},
                    }}>Контакты:</Typography>
                    <Paper sx={{
                        p: {xs: 3, md: 6}
                    }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} textAlign='center'>
                                <Typography variant="h4"sx={{
                                    color: lightBlue[900],
                                }}>Полное наименование</Typography>
                                <Typography mb={2}>Общество с ограниченной ответственностью "Мономакс Интеллектуальные Технологии"</Typography>
                                <Typography variant="h4"sx={{
                                    color: lightBlue[900],
                                }}>ИНН</Typography>
                                <Typography mb={2}>7814706302</Typography>
                                <Typography variant="h4"sx={{
                                    color: lightBlue[900],
                                }}>Email</Typography>
                                <Typography mb={2}>info@mittechlab.ru</Typography>
                                <Typography variant="h4" sx={{
                                    color: lightBlue[900],
                                }}>Телефон</Typography>
                                <Typography mb={2}>+7 962 726 2431</Typography>
                                <Typography variant="h4" sx={{
                                    color: lightBlue[900],
                                }}>Адрес</Typography>
                                <Typography mb={2}>197374, г. Санкт-Петербург, вн. тер. г. Муниципальный округ №65, ул. Стародеревенская, д. 11, к. 2, лит. А, оф. 225</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </main>
                <Box component="footer" sx={{bgcolor: 'background.paper', my: 6}}>
                    <Container maxWidth="lg">
                        <Typography variant="body2" color="text.secondary" align="center">
                            {'Copyright © '}
                            <Link color="inherit" href="https://mittechlab.ru/">
                                mittechlab.ru
                            </Link>{' '}
                            {new Date().getFullYear()}
                            {'.'}
                        </Typography>
                    </Container>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default App;
