import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Avatar, CardHeader} from "@mui/material";
import lightBlue from "@mui/material/colors/lightBlue";
import grey from "@mui/material/colors/grey";

function Project(props) {
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>

      <Card sx={{ minHeight: {xs: 'auto', md: post.number !== 7 ? '400px' : '200px'}}}>
        <CardHeader
            sx={{
                background: grey[100],
            }}
            avatar={
              <Avatar sx={{ bgcolor: lightBlue[800] }} aria-label="recipe">
                0{post.number}
              </Avatar>
            }
            title={post.title}
            subheader={post.description}
        />

        <CardContent>
            <Typography variant="p"  sx={{
                color: lightBlue[900],
            }}>{post?.descriptionListTitle} </Typography>
          <Typography variant="body2" color="text.secondary">
            <ul>
            {post.descriptionList.map((item) => (
                <li>{item}</li>
            ))}
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Project;
